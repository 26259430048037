import { Button, Fab, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

//:::::::::::::::::(Components):::::::::::::::::://

import ArsCarrousel from "../components/carrousel";
import AppbarTemplate from "../components/appbarTemplate";
import Mx from "../resources/hechoEnMx.png";
import Engineers from "../resources/Icons/engineers.png";
import Machineiry from "../resources/Icons/machineiry.png";
import Industrial from "../resources/industrial.png";
import Residencial from "../resources/residencial.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
//:::::::::::::::::::(Styles)::::::::::::::::::::::://
import * as styles from "../AppStyles";

const indexARS = () => {
  return (
    <Box sx={styles.bodyContainer}>
      <Box sx={styles.appBarContainer}>
        <AppbarTemplate />
      </Box>
      <ArsCarrousel />
      <Box sx={styles.fabContainer}>
        <a href="https://wa.me/524421796696?text=¡Hola!%20ARS%20Construcciones%20Metálicas">
          <Fab color={"success"} sx={styles.fabComponent}>
            <WhatsAppIcon />
          </Fab>
        </a>
      </Box>

      <Box sx={styles.mobileIndexBlock}>
        <Box
          src={Mx}
          component="img"
          alt="Hecho en México"
          sx={styles.mxIcon}
        ></Box>
        <Typography variant="body2" sx={styles.centeredTextIndex}>
          Somos ARS© Construcciones Metálicas.
          <br />
          Una empresa 100% Queretana con amplia experiencia en el suministro,
          fabricacion y montaje de estructuras metálicas.
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: " #f2f2f2" }}>
        <Box id="Servicios" sx={styles.indexSubtitles}>
          <Typography variant="h5" sx={styles.subtitlesText}>
            Servicios
          </Typography>
        </Box>
        <Box sx={styles.ourKnowledge}>
          <Box sx={styles.ourKnowledgeContainer}>
            <Typography variant="body2" sx={styles.ourKnowledgeText}>
              Nuestro conocimiento y capacitación constante nos permiten abordar
              toda clase de proyectos
            </Typography>
          </Box>
          <Box sx={styles.ourKnowledgeImage}>
            <Box component={"img"} src={Engineers} sx={styles.imageBox} />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.fromThereTo}>
        <Box sx={styles.fromThereToImage}>
          <Box component={"img"} src={Machineiry} sx={styles.imageBox} />
        </Box>
        <Typography variant="body2" sx={styles.fromThereToText}>
          Desde casas habitación, techumbres, trabajos residenciales, hasta
          estructuras industriales etc.
        </Typography>
      </Box>
      <Box sx={styles.indexSubtitles}>
        <Typography variant="h6" sx={styles.subtitlesTextB}>
          Somos expertos en la fabricacion de estructuras metálicas, en las
          áreas:
        </Typography>
      </Box>
      <Box sx={styles.areasContainer}>
        <Box component={"img"} src={Industrial} sx={styles.areasImages} />
        <Box component={"img"} src={Residencial} sx={styles.areasImages} />
      </Box>
      <Box sx={styles.indexSubtitles}>
        <Stack alignItems={"center"} spacing={1}>
          <Typography
            variant="h6"
            sx={{ lineHeight: "1.2", width: "70vw", fontWeight: "bolder" }}
          >
            ¡Platícanos tu proyecto!
          </Typography>
          <Typography variant={"body1"}>
            ventas@arsconstruccionesmetalicas.com
          </Typography>
          <Typography>Tel: 442 179 6696</Typography>

          <Button
            variant={"text"}
            sx={{
              // backgroundColor: "#1877f2",
              borderRadius: "6px",
              width: "260px",
              height: "46px",
              color: "white",
            }}
          >
            <Typography variant={"button"} sx={{ paddingRight: "12px" }}>
              Siguenos en facebook
            </Typography>
            <FacebookIcon />
          </Button>
        </Stack>
      </Box>
      <Box sx={{ backgroundColor: "black" }}>
        <Box sx={styles.footerArea}>
          <Box sx={styles.footerGrid}>
            <Typography
              variant="caption"
              sx={{ color: "grey", textAlign: "center", padding: "40px" }}
            >
              Todos los derechos reservados.
              <br />© 2023 ARS Construcciones Metálicas.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default indexARS;
