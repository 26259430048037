import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Building from "../img/Building.jpg";
const building = () => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box>
        <img
          src={Building}
          style={{ width: "350px", height: "auto" }}
          alt="imagen"
        />
      </Box>
      <Typography variant="h6">Proximamente. ⏳</Typography>
      <Typography>Sitio en fase final de desarrollo.</Typography>

      <Typography
        variant="caption"
        sx={{ textAlign: "center", paddingTop: "20px" }}
      >
        Todos los derechos reservados.
        <br />© 2023 ARS Construcciones metálicas.
      </Typography>
    </Box>
  );
};

export default building;
