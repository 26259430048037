import { HashRouter, Link, Route, Routes } from "react-router-dom";
import Welcome from "./pages/welcome.js";
import Inicio from "./pages/indexARS";
import NotFound from "./pages/notFoundPage";
import Building from "./pages/building.js";
export default function App() {
  return (
    <HashRouter>
      <Link to="/"></Link>
      <Link to="/inicio"></Link>
      <Link to="/building"></Link>
      <Link to="/*"></Link>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/inicio" element={<Inicio />} />
        <Route path="/building" element={<Building />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </HashRouter>
  );
}
