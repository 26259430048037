import { createTheme } from "@mui/material/styles";

/**
 * Default theme
 */
const defaultTheme = {
  components: {
    MuiToolbar: {
      top: 10,
      styleOverrides: {
        dense: {
          minHeight: 1,
          maxHeight: 1,
          top: 25,
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#FFFF",
      light: "#EFFFFF",
      dark: "#004C4E",
    },
    secondary: {
      main: "#77592F",
      light: "#8F7D66",
      dark: "#5F4625",
    },
    background: {
      paper: "#F5F5F5",
      default: "#FCFCFC",
    },

    text: {
      primary: "#626262",
      contrastText: "#FFFFFF",
    },
    grey: {
      neutral: "#EAEAEA",
    },
    yellow: {
      dark: "#7A5810",
    },
  },
};
export default createTheme(defaultTheme);
