import React from "react";
import { render } from "react-dom";
import Carousel from "./carouselTemplate";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ArsCarrousel = () => <Carousel />;

render(<ArsCarrousel />, document.getElementById("root"));

export default ArsCarrousel;
