// import * as React from "react";
// import Box from "@mui/material/Box";
// import Drawer from "@mui/material/Drawer";
// import Button from "@mui/material/Button";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemText from "@mui/material/ListItemText";
// import MenuIcon from "@mui/icons-material/Menu";
// import { IconButton } from "@mui/material";

// export default function TemporaryDrawer() {
//   const [state, setState] = React.useState({
//     right: false,
//   });

//   const toggleDrawer = (anchor, open) => (event) => {
//     if (
//       event.type === "keydown" &&
//       (event.key === "Tab" || event.key === "Shift")
//     ) {
//       return;
//     }

//     setState({ ...state, [anchor]: open });
//   };

//   const list = (anchor) => (
//     <Box
//       sx={{
//         color: "white",
//         width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
//       }}
//       role="presentation"
//       onClick={toggleDrawer(anchor, false)}
//       onKeyDown={toggleDrawer(anchor, false)}
//     >
//       <List>
//         {["Proyectos", "Nosotros", "Contacto"].map((text, index) => (
//           <ListItem key={text} disablePadding>
//             <ListItemButton>
//               <ListItemText primary={text} />
//             </ListItemButton>
//           </ListItem>
//         ))}
//       </List>
//     </Box>
//   );

//   return (
//     <Box>
//       {["right"].map((anchor) => (
//         <React.Fragment key={anchor}>
//           <IconButton onClick={toggleDrawer(anchor, true)}>
//             {anchor}
//             <MenuIcon />
//           </IconButton>
//           <Drawer
//             sx={{
//               "& .MuiDrawer-paper": {
//                 backgroundColor: "black",
//               },
//             }}
//             anchor={anchor}
//             open={state[anchor]}
//             onClose={toggleDrawer(anchor, false)}
//           >
//             {list(anchor)}
//           </Drawer>
//         </React.Fragment>
//       ))}
//     </Box>
//   );
// }

import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import * as styles from "../AppStyles";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const MobileMenuOptionsTemplate = () => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  return (
    <Box sx={styles.mobileMenuOptions}>
      <IconButton onClick={handleClick}>
        <MenuIcon sx={{ color: "white" }} />
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "black",
            color: "white",
            width: "50vw",
          },
        }}
      >
        <List>
          <ListItem button onClick={() => setOpen(false)}>
            <ListItemText primary="Nosotros" />
          </ListItem>
          <ListItem button onClick={() => setOpen(false)}>
            <ListItemText primary="Servicios" />
          </ListItem>
          <ListItem button onClick={() => setOpen(false)}>
            <ListItemText primary="Contacto" />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default MobileMenuOptionsTemplate;
