import background from "./img/ars_backgroundshade.png";
import theme from "./resources/theme/default";

export const headerContainer = {
  zIndex: "1",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};
export const appBarContainer = {
  zIndex: 2,
  position: "relative",
  display: "flex",
  justifyContent: "center",
};
export const bodyContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100vw",
  height: "auto",
  backgroundColor: "#0d0d0d",
};
export const fabContainer = {
  zIndex: "1",
  position: "sticky",
  top: "80vh",
  paddingLeft: "77vw",
  [theme.breakpoints.up("md")]: {
    top: "85vh",
    paddingLeft: "92vw",
  },
};
export const fabComponent = {
  backgroundColor: "#25D366",
  color: "white",
  transform: "scale(1.2)",
  [theme.breakpoints.up("md")]: {
    transform: "scale(1.5)",
  },
};

export const wColor = {
  color: "white",
};

export const wrapperStyles = {
  zIndex: 1,
  display: "grid",
  justifyContent: "center",
  height: "100vh",
  width: "100vw",
  gridTemplateRows: "1fr 2.8fr 1fr 1fr",
  gridTemplateColumns: ".5fr 4fr .5fr",
  backgroundImage: `url(${background})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  // marginBottom: '0px',
  margin: 0,
  [theme.breakpoints.down("md")]: {
    backgroundPosition: "-150px ",
    backgroundSize: "1500px",
  },
};
export const gridSocialNetworks = {
  display: "grid",
  zIndex: "2",
  justifyContent: "center",
  gridColumnStart: "2",
  gridRowStart: "1",
};
export const socialNetworks = {
  color: "white",
};
// export const netLogos = {
//   height: "2px",
//   width: "auto",
// };
export const slogan = {
  color: "white",
  textAlign: "center",
  fontWeight: "normal",
};
export const centerArea = {
  display: "grid",
  gridColumnStart: "2",
  gridRowStart: "2",
  justifyItems: "center",
};
export const downArea = {
  display: "grid",
  gridColumnStart: "2",
  gridRowStart: "3",
};
export const footerArea = {
  display: "flex",
  gridColumnStart: "2",
  gridRowStart: "4",
  justifyContent: "center",
};
export const logoGrid = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
};
export const sloganGrid = {
  display: "flex",
  alignItems: "center",
};
export const buttonGrid = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
export const footerGrid = {
  display: "flex",
  alignItems: "center",
};
export const img = {
  width: "auto",
  height: "100px",
  display: "flex",
  justifyContent: "center",
};
export const toolbar = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  gridTemplateRows: "1fr",
  justifyContent: "center",
  backgroundColor: "black",
};
export const imgAppBar = {
  width: "auto",
  height: "60px",
  display: "flex",
  justifyContent: "center",
  margin: "20px",
  [theme.breakpoints.down("md")]: {
    height: "35px",
  },
};
export const mobileMenuOptions = {
  gridColumn: "3/4",
  gridTemplateRows: "1/2",
  display: "flex",
  justifyContent: "end",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
};
export const meetButton = {
  color: "white",
};

export const buttonsDesktop = {
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
};

export const carrouselContainer = {
  zIndex: 0,
  width: "100vw",
  height: "450px",
  [theme.breakpoints.down("md")]: {
    height: "200px",
  },
};
export const textUbicationCarrousel = {
  height: "500px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    height: "200px",
  },
};
export const carrouselText = {
  color: "white",
  [theme.breakpoints.up("sm")]: {
    fontSize: "42px",
    fontWeight: "lighter",
  },
};

//:::::::::::::::::::::(Index Styles):::::::::::::::::::::://
export const indexSubtitles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "20px",
  textAlign: "center",
  color: "white",
  [theme.breakpoints.up("sm")]: {},
};

export const subtitlesText = {
  lineHeight: "1.2",
  width: "70vw",
  fontWeight: "bolder",
  color: "black",
  [theme.breakpoints.up("sm")]: { fontSize: "36px" },
};
export const subtitlesTextB = {
  lineHeight: "1.2",
  width: "70vw",
  fontWeight: "bolder",
  [theme.breakpoints.up("sm")]: { fontSize: "36px" },
};
export const areasContainer = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    justifyContent: "center",
  },
};
export const areasImages = {
  width: "300px",
  height: "auto",
  padding: "20px",
};
export const mobileIndexBlock = {
  paddingBottom: "72px",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    paddingTop: "56px",
    paddingBottom: "76px",
  },
};

export const mxIcon = {
  width: "46px",
  padding: "20px",
  [theme.breakpoints.up("sm")]: {
    width: "76px",
  },
};

export const centeredTextIndex = {
  textAlign: "center",
  width: "70vw",
  color: "white",
  [theme.breakpoints.up("sm")]: {
    fontSize: "24px",
  },
};

export const ourKnowledge = {
  display: "grid",
  gridTemplateColumns: "2fr 1fr",
  backgroundColor: " #f2f2f2",
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "1fr 1fr",
    padding: "60px",
  },
};
export const ourKnowledgeContainer = {
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    justifyContent: "end",
  },
};
export const ourKnowledgeText = {
  display: "flex",
  alignItems: "end",
  textAlign: "end",
  gridColumn: "1/2",
  gridRow: "1/2",
  padding: "34px",
  color: "black",
  [theme.breakpoints.up("sm")]: {
    fontSize: "24px",
    width: "30vw",
  },
};

export const ourKnowledgeImage = {
  gridColumn: "2/3",
  gridRow: "1/2",
  padding: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const fromThereTo = {
  display: "grid",
  gridTemplateColumns: "1fr 2fr",
  [theme.breakpoints.up("sm")]: {
    padding: "60px",
    gridTemplateColumns: "1fr 1fr",
  },
};

export const fromThereToImage = {
  gridColumn: "1/2",
  gridRow: "1/2",
  padding: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const fromThereToText = {
  gridColumn: "2/3",
  gridRow: "1/2",
  padding: "24px",
  display: "flex",
  alignItems: "center",
  color: "white",
  [theme.breakpoints.up("sm")]: {
    width: "30vw",
    fontSize: "24px",
  },
};

export const imageBox = {
  width: "90px",
  height: "auto",
  [theme.breakpoints.up("sm")]: {
    width: "140px",
  },
};
