import React from "react";
import * as styles from "../AppStyles";
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import logo from "../resources/ars_logo.png";

import { ThemeProvider } from "@mui/system";
import theme from "../resources/theme/default";

import { Link } from "react-router-dom";
const Welcome = () => {
  return (
    <Box sx={styles.wrapperStyles}>
      <Box sx={styles.gridSocialNetworks}>
        <Stack direction="row" spacing={1}>
          <IconButton sx={styles.wColor}>
            <FacebookIcon />
          </IconButton>
          <IconButton sx={styles.wColor}>
            <WhatsAppIcon />
          </IconButton>
          <IconButton sx={styles.wColor}>
            <InstagramIcon />
          </IconButton>
        </Stack>
      </Box>
      <Box sx={styles.centerArea}>
        <Box sx={styles.logoGrid}>
          <Box sx={styles.img} src={logo} component="img" alt={"logo"}></Box>
        </Box>
        <Box sx={styles.sloganGrid}>
          <Typography variant="h6" sx={styles.slogan}>
            Ingeniería, diseño y construcción.
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.downArea}>
        <Box sx={styles.buttonGrid}>
          <ThemeProvider theme={theme}>
            <Link to={"/inicio"} style={{ textDecoration: "none" }}>
              <Button variant="outlined">Conócenos</Button>
            </Link>
          </ThemeProvider>
        </Box>
      </Box>
      <Box sx={styles.footerArea}>
        <Box sx={styles.footerGrid}>
          <Typography
            variant="caption"
            sx={{ color: "white", textAlign: "center" }}
          >
            Todos los derechos reservados.
            <br />© 2023 ARS Construcciones metálicas.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Welcome;
