import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import img1 from "../resources/imagen1.png";
import img2 from "../resources/imagen2.png";
import img3 from "../resources/imagen3.png";
import img4 from "../resources/imagen4.png";
import img5 from "../resources/imagen5.png";

import * as styles from "../AppStyles";

const getConfigurableProps = () => ({
  showStatus: false,
  infiniteLoop: true,
  autoPlay: true,
  interval: 2500,
  transitionTime: 1000,
  showThumbs: false,
  showArrows: false,
  showIndicators: false,
});

const carouselTemplate = () => {
  return (
    <Box sx={styles.carrouselContainer}>
      <Carousel
        {...getConfigurableProps()}
        autoPlay
        animationHandler="slide"
        swipeable={false}
      >
        <Box sx={styles.textUbicationCarrousel}>
          <Box sx={{ zIndex: 1, position: "absolute" }}>
            <Typography variant="h6" sx={styles.carrouselText}>
              Ingeniería, diseño y construcción.
            </Typography>
          </Box>
          <img src={img1} alt="uno" />
        </Box>
        <Box sx={styles.textUbicationCarrousel}>
          <Box sx={{ zIndex: 1, position: "absolute" }}>
            <Typography variant="h6" sx={styles.carrouselText}>
              Mantenimiento industrial
            </Typography>
          </Box>
          <img src={img2} alt="dos" />
        </Box>
        <Box sx={styles.textUbicationCarrousel}>
          <Box sx={{ zIndex: 1, position: "absolute" }}>
            <Typography variant="h6" sx={styles.carrouselText}>
              Estructuras metálicas ligeras
            </Typography>
          </Box>
          <img src={img3} alt="tres" />
        </Box>
        <Box sx={styles.textUbicationCarrousel}>
          <Box sx={{ zIndex: 1, position: "absolute" }}>
            <Typography variant="h6" sx={styles.carrouselText}>
              Estructuras metálicas pesadas
            </Typography>
          </Box>
          <img src={img4} alt="cuatro" />
        </Box>
        <Box sx={styles.textUbicationCarrousel}>
          <Box sx={{ zIndex: 1, position: "absolute" }}>
            <Typography variant="h6" sx={styles.carrouselText}>
              Corte CNC
            </Typography>
          </Box>
          <img src={img5} alt="cinco" />
        </Box>
      </Carousel>
    </Box>
  );
};

export default carouselTemplate;
