import { AppBar, Stack, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import React from "react";
import MobileMenuOptionsTemplate from "./mobileMenuOptionsTemplate";
import logo from "../resources/ars_logo.png";
import * as styles from "../AppStyles";
import theme from "../resources/theme/default";

const appbarTemplate = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={styles.toolbar}>
          <Box
            sx={{
              gridColumn: "2/3",
              gridRow: "1/2",
              display: "flex",
              justifyContent: "center",
              [theme.breakpoints.up("sm")]: {
                gridColumn: "1/3",
                gridRow: "1/2",
                justifyContent: "space-between",
                marginLeft: "30px",
              },
            }}
          >
            <Box
              sx={styles.imgAppBar}
              src={logo}
              component="img"
              alt={"logo"}
            ></Box>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "end", marginRight: "30px" }}
          >
            <Stack direction="row" spacing={1} sx={styles.buttonsDesktop}>
              <Button>
                <Typography variant="button" sx={{ color: "white" }}>
                  Proyectos
                </Typography>
              </Button>
              <Button>
                <Typography variant="button" sx={{ color: "white" }}>
                  Nosotros
                </Typography>
              </Button>
              <Button>
                <Typography variant="button" sx={{ color: "white" }}>
                  Contacto
                </Typography>
              </Button>
            </Stack>
          </Box>
          <MobileMenuOptionsTemplate />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default appbarTemplate;
